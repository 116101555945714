import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import Model from "react-modal";

export default function AddProductTable({
  varients,
  removeVarient,
  editVarient,
  category_name,
  product_name,
}) {
  const menu = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [view, setView] = useState(false);
  const { varientOptions } = useSelector((state) => state.ProductReducer);
  const { units } = useSelector((state) => state.ProductReducer);
  const [showDelDialog, setShowDelDialog] = useState(false);

  let variantId;
  const accept = () => {
    removeVarient(selectedItem?.id);
  };

  const reject = () => {
    setShowDelDialog(false);
  };

  const handleView = () => {
    setView(true);
  };

  let items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        editVarient(selectedItem);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setShowDelDialog(true);
      },
    },
  ];

  const handlePrint = useReactToPrint({
    pageStyle:
      "@page { size: 2in 1in; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    content: () => document.getElementById(variantId),
  });

  const actionBtn = (rowData) => (
    <React.Fragment>
      <div>
        <i
          className="pi pi-cog text-secondary cursor-pointer"
          onClick={(e) => {
            menu.current.toggle(e);
            setSelectedItem(rowData);
          }}
        ></i>
        <Menu model={items} popup ref={menu} className="p-0 z-50" />
      </div>
    </React.Fragment>
  );

  const printBarcodeBody = (rowData) => {
    // Find unit name or default to 'N/A'
    const unitName =
      units.find((unit) => unit.id === rowData.unit)?.name || "N/A";

    // Determine whether to display size or unit
    const displayText = rowData?.size ? `${rowData.size}` : `${unitName}`;

    console.log(rowData);

    return (
      <React.Fragment>
        <div className="hidden">
          {rowData?.barcode_img ? (
            <div
              className="text-[10px] overflow-hidden"
              id={"receipt-component-variant-" + rowData?.varient_id}
              style={{
                width: "2in",
                height: "1in",
              }}
            >
              <div
                className="flex py-3 flex-col leading-none text-[15px] clear-none"
                style={{ width: "2in", height: "0.6in" }}
              >
                <div className="flex justify-center font-bold">
                  {product_name}
                </div>
                <div className="flex justify-center font-bold">
                  <span>Rs {rowData?.price.toLocaleString("en-IN")}</span>
                  <span className="ml-2">/ {displayText}</span>
                </div>
                <div className="flex justify-center font-bold">
                  <span>Tehzeeb Designer</span>
                </div>
              </div>
              <div className="flex justify-center font-bold">
                <img
                  src={`${rowData?.barcode_url}${rowData?.barcode_img}`}
                  className=""
                  style={{ width: "1.5in", height: "0.33in" }}
                />
              </div>
            </div>
          ) : (
            <div
              className="flex mt-14 ml-4"
              id={"receipt-component-variant-" + rowData?.id}
            >
              <p>
                *The barcode will be generated after you submit product
                information.
              </p>
            </div>
          )}
        </div>
        <Button
          label=""
          icon="pi pi-print"
          className="p-black-btn"
          size="small"
          type="button"
          onClick={() => {
            variantId = `receipt-component-variant-${rowData?.varient_id}`;
            console.log(rowData.varient_id);
            return handlePrint();
          }}
        />
      </React.Fragment>
    );
  };

  const priceBody = (rowData) => `RS ${rowData.price.toLocaleString("en-IN")}`;

  const purchasePriceBody = (rowData) => {
    if (!rowData || rowData.purchasePrice == null) return "Rs 0";

    return `Rs ${rowData.purchasePrice.toLocaleString("en-IN")}`;
  };

  const unitBody = (rowData) => {
    if (rowData.unit) {
      const unit = units.find((obj) => obj.id === rowData.unit);
      return ` ${unit?.name}`;
    }
  };

  const idBody = (rowData, options) => options.rowIndex + 1;

  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={varients}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          size="small"
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
        >
          <Column field="id" header="ID #" body={idBody} />
          <Column
            field="variant"
            header="Variant"
            headerClassName="capitalize"
            body={(rowData) => {
              const matchingOptions = varientOptions.filter(
                (option) => option.unit_id === rowData.unit
              );
              return matchingOptions
                .map((option) => {
                  const value = rowData[option.label];
                  return value ? `${value}` : null;
                })
                .filter(Boolean)
                .join(", ");
            }}
          />
          <Column field="quantity" header="Quantity" />
          <Column field="barcode" header="barcode" />
          <Column field="color" header="Color" />
          <Column field="design" header="Design" />
          <Column field="unit" header="Unit" body={unitBody} />
          <Column
            field="price"
            header="Purchase Price"
            body={purchasePriceBody}
          />
          <Column field="price" header="Sale Price" body={priceBody} />
          <Column header="Action" body={actionBtn} />
          <Column header="Barcode" body={printBarcodeBody} />
        </DataTable>
      </div>

      <ConfirmDialog
        message="Do you want to delete this record?"
        header="Delete confirmation"
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Yes"
        visible={showDelDialog}
        onHide={() => setShowDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />
    </div>
  );
}
