import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

export default function OrderTable() {
  const { allOrders } = useSelector((state) => state.OrderReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const [selectedSales, setSelectedSales] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="flex justify-between">
        <span className="p-input-icon-left flex w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12 ">
          <InputText
            className="w-full p-primary-input"
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const totalSalesBody = (rowData) => {
    return `RS ${rowData.total_sales}`;
  };

  const totalDiscountBody = (rowData) => {
    return `RS ${rowData.total_discount}`;
  };

  const totalAmountBody = (rowData) => {
    return `RS ${rowData.total_amount}`;
  };

  const productBody = (rowData) => {
    return `${rowData?.products?.length}`;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.is_active ? "Active" : "Deactive"}
        pt={{
          root: {
            className: getSeverity(rowData),
          },
        }}
      />
    );
  };

  const saleStatusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status === "refund" ? "Refund" : "Invoice"}
        pt={{
          root: {
            className: getSaleSeverity(rowData),
          },
        }}
      />
    );
  };

  const getSeverity = (data) => {
    switch (data.is_active) {
      case 1:
        return "bg-green";

      case 0:
        return "bg-red";

      default:
        return null;
    }
  };

  const getSaleSeverity = (data) => {
    switch (data.status) {
      case "refund":
        return "bg-red";

      case "invoice":
        return "bg-green";

      default:
        return null;
    }
  };

  const dateAndTimeBody = (rowData) => {
    const date = new Date(rowData.created_at);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    let hour = date.getHours();
    const minute = String(date.getMinutes()).padStart(2, "0");
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      hour = hour === 12 ? hour : hour - 12;
    }

    hour = String(hour).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}, ${hour}:${minute} ${period}`;
    return formattedDate;
  };

  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={allOrders}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className=""
          header={header}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          selection={selectedSales}
          onSelectionChange={(e) => setSelectedSales(e.value)}
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
          onRowClick={(e) =>
            navigate(
              user?.role_id == 1
                ? "/edit-sales/" + e.data.id
                : "/edit-sales-sales/" + e.data.id
            )
          }
          paginatorClassName=""
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
          <Column
            field="id"
            sortable
            header="Invoice #"
            style={{ minWidth: "130px" }}
            body={(rowData) => String(rowData?.id).padStart(5, "0")}
          />
          <Column
            field="customer_name"
            sortable
            header="Customer Name"
            style={{ minWidth: "180px" }}
          />
          <Column
            field="customer_phone"
            sortable
            header="Customer Phone"
            style={{ minWidth: "180px" }}
            className="text-center"
          />

          <Column
            field="total_sales"
            sortable
            header="Total Sales"
            body={totalSalesBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />
          <Column
            field="total_discount"
            sortable
            header="Total Discount"
            body={totalDiscountBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />

          <Column
            sortable
            field="total_amount"
            header="Total Amount"
            body={totalAmountBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />

          <Column
            header="Total Products"
            body={productBody}
            style={{ minWidth: "150px" }}
            className="text-center"
          />

          <Column
            field="status"
            sortable
            header="Sale Status"
            body={saleStatusBodyTemplate}
            style={{ minWidth: "130px" }}
          />

          <Column
            field="is_active"
            sortable
            header="Status"
            body={statusBodyTemplate}
            style={{ minWidth: "110px" }}
          />

          <Column
            field="created_at"
            sortable
            header="Date"
            body={dateAndTimeBody}
            style={{ minWidth: "170px" }}
          />
        </DataTable>
      </div>
    </div>
  );
}
