import { SET_SIZE_OPTION, SET_WORK_ASSIGNMENT } from "../Types/SetupTypes";
const initialState = {
  allWork: [],
  sizeOptions: [],
};

const SetupReducer = (state = initialState, action) => {
  if (action.type === SET_WORK_ASSIGNMENT) {
    return { ...state, allWork: action.payLoad };
  } else if (action.type === SET_SIZE_OPTION) {
    return { ...state, sizeOptions: action.payLoad };
  } else {
    return state;
  }
};

export default SetupReducer;
