import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderTable from "./OrderTable";
import { getOrders } from "../../store/AsyncMethods/OrderMethod";

export default function OrderMain() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (user) {
      dispatch(getOrders());
    }
  }, []);

  return (
    <div className="">
      <div className="my-4">
        <OrderTable />
      </div>
    </div>
  );
}
