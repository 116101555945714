import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCategory,
  getSubCategory,
} from "../../store/AsyncMethods/CategoryMethod";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";

import {
  addProduct,
  getArticleNumForProduct,
  getOptionValues,
  getProductUnit,
  getProducts,
  getVarientOption,
  getspecificProduct,
  updateProduct,
} from "../../store/AsyncMethods/ProductMethod";
import AddProductTable from "./AddProductTable";
import AddVarientForm from "./AddVarientForm";
import AddProductForm from "./AddProductForm";
import {
  RESET_ARTICLE_NUM,
  RESET_SPECIFIC_PRODUCT,
} from "../../store/Types/ProductTypes";

export default function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const location = useLocation();

  const [showVarientForm, setShowVarientForm] = useState(false);

  const { error, user } = useSelector((state) => state.AuthReducer);
  const { varientOptions, specificProduct, articleNum } = useSelector(
    (state) => state.ProductReducer
  );

  const { subCategories } = useSelector((state) => state.CategoryReducer);

  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const [varientUnit, setVarientUnit] = useState(1);

  const [validateVarient, setValidateVarient] = useState(false);

  const varientOptionsValidation = varientOptions.reduce((acc, option) => {
    acc[option.label] = Yup.object().required(`${option.label} is required`);
    return acc;
  }, {});

  const [editingVarient, setEditingVarient] = useState(null);

  const editVarient = (varient) => {
    setEditingVarient(varient);
    setShowVarientForm(true);
  };

  useEffect(() => {
    if (specificProduct) {
      setVarientUnit(specificProduct.varients[0]?.unit || 1);
      formik.setValues({
        title: specificProduct.title || "",
        articleName: specificProduct.article_name || "",
        description: specificProduct.description || "",
        status: specificProduct.is_active === 1 ? true : false,
        price: specificProduct.price || null,
        discount: specificProduct.discount || 0,
        quantity: specificProduct.quantity || 0,
        subCategoryId: specificProduct.sub_category_id || "",
        categoryId: specificProduct.category_id || "",
        samePrice: specificProduct.is_same_price === 1 ? true : false,
        isDiscountPercentage:
          specificProduct.is_discount_percentage === 1 ? true : false,
        userId: user.id,
        varients: specificProduct.varients || [],
        varient: {
          unit: 1,
          quantity: "0",
          price: null,
          design: "",
          color: "",
        },
      });
    }
  }, [specificProduct]);

  const formik = useFormik({
    initialValues: {
      title: specificProduct ? specificProduct.title : "",
      articleName: specificProduct ? specificProduct.article_name : "",
      description: specificProduct ? specificProduct.description : "",
      status: specificProduct
        ? specificProduct.is_active === 1
          ? true
          : false
        : true,
      price: specificProduct ? specificProduct.price : null,
      quantity: specificProduct ? specificProduct.quantity : 0,
      subCategoryId: specificProduct ? specificProduct.sub_category_id : "",
      categoryId: specificProduct ? specificProduct.category_id : "",
      samePrice: specificProduct
        ? specificProduct.is_same_price === 1
          ? true
          : false
        : true,
      userId: user.id,
      varients: specificProduct ? specificProduct.varients : [],
      discount: specificProduct ? specificProduct.price : 0,
      isDiscountPercentage: specificProduct
        ? specificProduct.is_discount_percentage === 1
          ? true
          : false
        : true,
      varient: {
        unit: 1,
        quantity: 0,
        price: null,
        design: "",
        color: "",
      },
    },
    validationSchema: () => {
      return Yup.object({
        subCategoryId: Yup.number().required("Sub Category Required"),
        categoryId: Yup.number().required("Category Required"),
        status: Yup.boolean().required("Status Required"),
        price: Yup.number().required("Price Required"),
        quantity: Yup.number().required("Quantity Required"),
        title: Yup.string().required("Product Title Required"),
        articleName: Yup.string().required("Article Number Required"),
        description: Yup.string(),
        samePrice: Yup.boolean(),
        discount: formik.values.isDiscountPercentage
          ? Yup.number()
              .min(0, "Discount must be greater than or equal to 0")
              .max(100, "Discount must be less than or equal to 100")
          : Yup.number().min(0, "Discount must be greater than or equal to 0"),

        varient: validateVarient
          ? Yup.object().shape({
              quantity: Yup.number().required("Quantity is required"),
              price: Yup.number().required("Price is required"),
            })
          : Yup.object(),
      });
    },

    onSubmit: async (data) => {
      const updatedData = {
        ...data,
        varients: data.varients.map((variant) => ({
          ...variant,
          price: data.samePrice ? data.price : variant.price,
        })),
      };

      if (specificProduct) {
        dispatch(updateProduct(updatedData, productId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_PRODUCT });
            dispatch(getProducts());
            navigate(user?.role_id == 1 ? "/products" : "/products-sales");
          }
        });
      } else {
        dispatch(addProduct(updatedData)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch(getProducts());
            navigate(user?.role_id == 1 ? "/products" : "/products-sales");
          }
        });
      }
    },
  });

  const removeVarient = (varientId) => {
    formik.setValues((prevValues) => {
      const updatedVarients = prevValues.varients.filter(
        (varient) => varient.id !== varientId
      );
      return { ...prevValues, varients: updatedVarients };
    });
  };

  useEffect(() => {
    if (formik?.values?.samePrice) {
      formik.setValues({
        ...formik.values,
        varient: {
          ...formik.values.varient,
          price: formik?.values?.price,
        },
        varients: formik.values.varients.map((variant) => ({
          ...variant,
          price: formik?.values?.price,
        })),
      });
    } else {
      formik.setValues({
        ...formik.values,
        price: 0,
      });
    }
  }, [formik?.values?.samePrice, formik?.values?.price]);

  useEffect(() => {
    if (varientOptions.length > 0) {
      varientOptions.map((option) => {
        formik.initialValues.varient = {
          ...formik.initialValues.varient,
          [option.label]: "",
        };
      });
    }
  }, [varientOptions]);

  useEffect(() => {
    if (formik.values.categoryId) {
      const filter = subCategories.filter(
        (item) => item.category_id === formik.values.categoryId
      );

      if (filter) {
        setFilterSubCategories(filter);
      }
    }
  }, [formik.values.categoryId]);

  useEffect(() => {
    if (user) {
      dispatch(getCategory());
      dispatch(getSubCategory());
      dispatch(getOptionValues());
      dispatch(getArticleNumForProduct());
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getVarientOption(varientUnit));
    }
  }, [varientUnit]);

  useEffect(() => {
    if (!productId && articleNum) {
      formik.setFieldValue("articleName", articleNum);
      dispatch({ type: RESET_ARTICLE_NUM });
    }
  }, [articleNum]);

  useEffect(() => {
    if (productId) {
      dispatch(getspecificProduct(productId));
    } else {
      formik.resetForm();
      dispatch({ type: RESET_SPECIFIC_PRODUCT });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_SPECIFIC_PRODUCT });
    formik.resetForm();
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      dispatch(getProductUnit());
    }
  }, []);

  useEffect(() => {
    setVarientUnit(formik.values.varient?.unit);
  }, [formik.values.varient?.unit]);

  return (
    <div className="mx-4">
      <form onSubmit={formik.handleSubmit}>
        <AddProductForm
          formik={formik}
          filterSubCategories={filterSubCategories}
          specificProduct={specificProduct}
        />

        <div>
          <AddVarientForm
            formik={formik}
            showVarientForm={showVarientForm}
            setShowVarientForm={setShowVarientForm}
            varientUnit={varientUnit}
            validateVarient={validateVarient}
            setValidateVarient={setValidateVarient}
            editingVarient={editingVarient}
            setEditingVarient={setEditingVarient}
          />
        </div>

        <div className="my-3">
          <AddProductTable
            varients={formik.values.varients}
            removeVarient={removeVarient}
            editVarient={editVarient}
            category_name={specificProduct?.category_name}
            product_name={specificProduct?.title}
          />
        </div>

        <div className="mt-16">
          <div className="flex justify-end gap-4">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                dispatch({ type: RESET_SPECIFIC_PRODUCT });
                formik.resetForm();
              }}
            />
            <Button
              label={"Submit"}
              icon="pi pi-check"
              className="p-secondary-btn"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
