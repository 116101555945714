import { Divider } from "primereact/divider";
import React, { useEffect } from "react";

export default function SalesCalculation({ formik }) {
  const calculateTotalSalePrice = () => {
    return formik?.values?.products?.reduce(
      (accumulator, currentItem) =>
        accumulator + currentItem.salePrice * currentItem.quantity,
      0
    );
  };

  const calculateTotalPurchasePrice = () => {
    return formik?.values?.products?.reduce(
      (accumulator, currentItem) =>
        accumulator + currentItem.purchasePrice * currentItem.quantity,
      0
    );
  };

  const calculateTotalDiscount = () => {
    return formik?.values?.products?.reduce(
      (accumulator, currentItem) => accumulator + currentItem.discount,
      0
    );
  };

  useEffect(() => {
    const totalSalePrice = calculateTotalSalePrice();
    const totalPurchasePrice = calculateTotalPurchasePrice();
    const totalDiscount = calculateTotalDiscount();
    const vatAmount = totalSalePrice * (formik.values?.vatPer / 100);

    formik.setFieldValue("totalPurchase", totalPurchasePrice || 0);
    formik.setFieldValue(
      "totalSales",
      formik.values.saleStatus === "refund"
        ? -totalPurchasePrice
        : totalPurchasePrice
    );
    formik.setFieldValue(
      "totalDiscount",
      formik.values.saleStatus === "refund" ? -totalDiscount : totalDiscount
    );
    formik.setFieldValue("vatAmount", vatAmount || 0);

    formik.setFieldValue(
      "totalAmount",
      formik.values.saleStatus === "refund"
        ? (totalPurchasePrice - totalDiscount - vatAmount) * -1
        : totalPurchasePrice + vatAmount - totalDiscount
    );
  }, [
    formik.values?.products,
    formik.values?.saleStatus,
    formik.values?.vatPer,
  ]);

  return (
    <div className="flex justify-end">
      <div className="w-full lg:w-7/12 xl:w-5/12">
        <div className="card px-4 pt-0 pb-4 shadow-md rounded-lg flex flex-col gap-2">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {formik.values?.saleStatus === "invoice"
                ? "Sales Summary"
                : "Refund Summary"}
            </span>
          </Divider>
          <div className="flex justify-between">
            <h1 className="text-lg font-semibold">Total Items</h1>
            <span className="text-lg">{formik?.values?.products?.length}</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">
              Total{" "}
              {formik.values?.saleStatus === "invoice" ? "Sales" : "Refund"}{" "}
              Price
            </h1>
            <span className="text-lg">
              RS {formik.values?.totalSales.toLocaleString("en-IN")}
            </span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">Discount</h1>
            <span className="text-lg">
              RS {formik.values?.totalDiscount.toLocaleString("en-IN")}
            </span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">VAT %</h1>
            <span className="text-lg">{formik.values?.vatPer}%</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">VAT Amount</h1>
            <span className="text-lg">
              RS {formik.values?.vatAmount.toLocaleString("en-IN")}
            </span>
          </div>

          <Divider pt={{ root: { className: "!my-2" } }} />

          <div className="flex justify-end gap-3">
            <h1 className="text-lg font-semibold">Total Amount:</h1>
            <span className="text-lg">
              RS {formik.values?.totalAmount.toLocaleString("en-IN")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
