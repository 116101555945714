import React, { useEffect, useState } from "react";
import Invoice from "./Invoice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getspecificSale } from "../../../store/AsyncMethods/SalesMethod";
import {
  getAllVarients,
  getProducts,
} from "../../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_SALES } from "../../../store/Types/SalesTypes";
import { useReactToPrint } from "react-to-print";

export default function SaleInvoiceMain() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allVarients, products } = useSelector((state) => state.ProductReducer);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const { saleId } = useParams();

  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
    }
  }, [saleId, dispatch]);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getAllVarients());
  }, [dispatch]);

  useEffect(() => {
    // Check if all data is loaded
    if (specificSale && allVarients.length > 0 && products.length > 0) {
      setIsDataLoaded(true);
    }
  }, [specificSale, allVarients, products]);

  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
    onAfterPrint: () => {
      dispatch({ type: RESET_SPECIFIC_SALES });
      navigate(user?.role_id == 1 ? "/sales" : "/sales-sales");
    },
  });

  useEffect(() => {
    if (isDataLoaded) {
      const timer = setTimeout(() => {
        handlePrint();
      }, 1000); // Adjust the delay as needed

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [isDataLoaded, handlePrint]);

  return (
    <div>
      <div className="bg-white !w-[80mm] mx-auto h-screen">
        <div id="receipt-component">
          <Invoice />
        </div>
      </div>
    </div>
  );
}
