import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   getCategory,
//   getSubCategory,
// } from "../../store/AsyncMethods/CategoryMethod";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";
import {
  getAllVarients,
  getProducts,
  getspecificVarientsByProduct,
} from "../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_PRODUCT } from "../../store/Types/ProductTypes";
import AddSalesForm from "./AddSalesForm";
// import { getSales } from "../../store/AsyncMethods/SalesMethod";
import { getCustomers } from "../../store/AsyncMethods/CustomerMethod";
import AddSalesProductForm from "./AddSalesProductForm";
import AddSalesProductTable from "./AddSalesProductTable";
import SalesCalculation from "./SalesCalculation";
import {
  addSale,
  getSales,
  getspecificSale,
  updateSales,
} from "../../store/AsyncMethods/SalesMethod";
import { TabMenu } from "primereact/tabmenu";

import { RESET_SPECIFIC_SALES } from "../../store/Types/SalesTypes";
import AddCustomerSize from "./AddCustomerSize";
import { color } from "framer-motion";
import { getsCategory } from "../../store/AsyncMethods/ScatMethod";
import { use } from "i18next";

export default function AddSales() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPosted, setIsPosted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const items = [
    { label: "Sales", icon: "pi pi-wrench" },
    { label: "Customer Size", icon: "pi pi-users" },
  ];
  const { saleId } = useParams();

  // const [showProductForm, setShowProductForm] = useState(true);

  const { error, user } = useSelector((state) => state.AuthReducer);
  const { specificVarients } = useSelector((state) => state.ProductReducer);

  const { specificSale } = useSelector((state) => state.SalesReducer);

  // const [filterSubCategories, setFilterSubCategories] = useState([]);

  const [validateVarient, setValidateVarient] = useState(true);
  const [isRefund, setIsRefund] = useState(false);

  const [currentProductQuantity, setCurrentProductQuantity] = useState(0);
  const [slectedProductQuantity, setSelectedProductQuantity] = useState(0);

  // const varientOptionsValidation = varientOptions.reduce((acc, option) => {
  //   acc[option.label] = Yup.object().required(`${option.label} is required`);
  //   return acc;
  // }, {});

  const [editingProduct, setEditingProduct] = useState(null);

  const editProduct = (product) => {
    setEditingProduct(product);
    // setShowProductForm(true);
  };

  useEffect(() => {
    if (specificSale) {
      console.log(specificSale.totalAmount)
      setIsPosted(specificSale?.is_posted === 1 ? true : false);
      formik.setValues({
        name: specificSale.customer_name || "",
        customerId: specificSale.customer_id || 1,
        customerSizeId: specificSale.customer_id || 1,
        sCategoryId: "",
        customerSizes: specificSale.customerSizes || [],
        customers: specificSale.customers || [],
        saleStatus: specificSale.status || "invoice",
        phone: specificSale.customer_phone || "03",
        totalPurchase: specificSale.total_purchase || 0,
        status: specificSale.is_active === 1 ? true : false,
        totalSales: specificSale.total_sales || 0,
        totalDiscount: specificSale.total_discount || 0,
        vatPer: specificSale.vat_per || 0,
        vatAmount: specificSale.vat_amount || 0,
        totalAmount: specificSale.total_amount || 0,
        userId: user.id,
        posted: specificSale.is_posted === 1 ? true : false || 0,
        unit: 0,
        products: specificSale.products || [],
        product: {
          purchasePrice: 0,
          salePrice: 0,
          product: "",
          varient: "",
          discount: 0,
          quantity: 0,
          total: 0,
          discount_value: 0,
          is_discount_percentage: true,
        },
      });
    }
  }, [specificSale]);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      customerId: 0,
      customerSizeId: 0,
      sCategoryId: 0,
      customerSize: {},
      customerSizes: [],
      customers: [],
      status: true,
      saleStatus: "invoice",
      products: [],
      userId: user.id,
      totalPurchase: 0,
      totalSales: 0,
      totalDiscount: 0,
      vatPer: 0,
      posted: 0,
      unit: "",
      color: "N/A",
      design: "N/A",
      vatAmount: 0,
      totalAmount: 0,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 0,
        total: 0,
        discount_value: 0,
        is_discount_percentage: true,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Customer Name Required"),
      saleStatus: Yup.string().required("Sale Status Required"),
      status: Yup.boolean().required("Status Required"),
      product: validateVarient
        ? Yup.object().shape({
            product: Yup.number().required("Product Required"),
            varient: Yup.number(),
            purchasePrice: Yup.number().required("Purchase Price is required"),
            salePrice: Yup.number().required("Sale Price is required"),
            discount: Yup.number().required("Discount is required"),
            quantity: Yup.number()
              .required("Quantity is required")
              .positive("Quantity should be greater than 0")
              .max(
                currentProductQuantity,
                "Quantity should be less than or equal to current product quantity."
              ),
            total: Yup.number().required("Total is required"),
            discount_value: Yup.number().required("Discount is required"),
            is_discount_percentage: Yup.boolean(),
          })
        : Yup.object(),
    }),

    onSubmit: async (data) => {
      if (specificSale) {
        dispatch(updateSales(data, saleId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_SALES });
            dispatch(getSales());
            navigate(user?.role_id == 1 ? "/sales" : "/sales-sales");
          }
        });
      } else {
        dispatch(addSale(data)).then((success) => {
          if (success.success) {
            formik.resetForm();
            dispatch(getSales());
            navigate(
              user?.role_id == 1
                ? "/invoice-sales/" + success.insertSaleId
                : "/invoice-sales-sales/" + success.insertSaleId
            );
          }
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.saleStatus === "refund") {
      // Convert totalSales, totalDiscount, and totalAmount to negative
      formik.setValues({
        ...formik.values,
        totalSales: -formik.values.totalSales,
        totalDiscount: -formik.values.totalDiscount,
        totalAmount: -formik.values.totalAmount,
      });
    } else {
      // Convert totalSales, totalDiscount, and totalAmount to positive
      formik.setValues({
        ...formik.values,
        totalSales: Math.abs(formik.values.totalSales),
        totalDiscount: Math.abs(formik.values.totalDiscount),
        totalAmount: Math.abs(formik.values.totalAmount),
      });
    }
  }, [formik.values.saleStatus]);

  const removeProduct = (productId) => {
    formik.setValues((prevValues) => {
      const updatedProduct = prevValues.products.filter(
        (product) => product.id !== productId
      );
      return { ...prevValues, products: updatedProduct };
    });
  };

  // useEffect(() => {
  //   if (formik?.values?.samePrice) {
  //     formik.setValues({
  //       ...formik.values,
  //       varient: {
  //         ...formik.values.varient,
  //         price: formik?.values?.price,
  //       },
  //     });
  //   } else {
  //     formik.setValues({
  //       ...formik.values,
  //       price: 0,
  //     });
  //   }
  // }, [formik?.values?.samePrice, formik?.values?.price]);

  // useEffect(() => {
  //   if (varientOptions.length > 0) {
  //     varientOptions.map((option) => {
  //       formik.initialValues.varient = {
  //         ...formik.initialValues.varient,
  //         [option.label]: "",
  //       };
  //     });
  //   }
  // }, [varientOptions]);

  useEffect(() => {
    if (formik.values.product?.product) {
      dispatch(getspecificVarientsByProduct(formik.values.product?.product));
    }
  }, [formik.values.product?.product]);

  useEffect(() => {
    if (user) {
      dispatch(getCustomers());
      dispatch(getProducts());
      dispatch(getAllVarients());
    }
    dispatch(getsCategory());
  }, []);

  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
      setValidateVarient(false);
      // setShowProductForm(false);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_SPECIFIC_SALES });
    formik.resetForm();
  }, [location.pathname]);

  // useEffect(() => {
  //   if (formik.values.product?.varient && specificVarients.length > 0) {
  //     const varient = specificVarients.find(
  //       (varient) => varient.varient_id === formik.values.product?.varient
  //     );

  //     if (varient) {
  //       formik.setFieldValue("product.purchasePrice", varient.price || "");
  //       setCurrentProductQuantity(varient.quantity);
  //     } else {
  //       setCurrentProductQuantity(0);
  //     }
  //   } else {
  //     setCurrentProductQuantity(0);
  //   }
  // }, [formik.values.product?.varient]);

  useEffect(() => {
    if (formik.values.product.purchasePrice && formik.values.product.quantity) {
      formik.setFieldValue(
        "product.total",
        formik.values.product.purchasePrice * formik.values.product.quantity -
          formik.values.product.discount || 0
      );
    }
  }, [
    formik.values.product.salePrice,
    formik.values.product.quantity,
    formik.values.product.discount,
  ]);

  // Prevent form submission on Enter key press
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (
      formik?.values?.product?.product &&
      formik?.values?.products?.length > 0
    ) {
      const pro = formik?.values?.products.find(
        (pro) => pro.product === formik?.values?.product?.product
      );

      if (pro) {
        setCurrentProductQuantity(currentProductQuantity - pro.quantity);
      }
    }
  }, [slectedProductQuantity]);

  return (
    <div className="mx-4">
      {saleId && (
        <TabMenu
          model={items}
          key={activeIndex}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          pt={{
            root: { className: "!overflow-hidden" },
            menu: {
              className: "!bg-transparent !inline-flex mb-4 ",
            },
            action: { className: "!bg-transparent" },
            label: { className: "!text-primary" },
            icon: { className: "!text-primary" },
          }}
        />
      )}
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_SALES });
            navigate(-1);
          }}
        />
      </div> */}
      {activeIndex === 0 && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                pointerEvents: isPosted && user.role_id != 1 ? "none" : "auto",
              }}
            >
              <AddSalesForm
                formik={formik}
                isRefund={isRefund}
                setIsRefund={setIsRefund}
              />

              <div>
                <AddSalesProductForm
                  formik={formik}
                  // showProductForm={showProductForm}
                  // setShowProductForm={setShowProductForm}
                  currentProductQuantity={currentProductQuantity}
                  setCurrentProductQuantity={setCurrentProductQuantity}
                  validateVarient={validateVarient}
                  setValidateVarient={setValidateVarient}
                  editingProduct={editingProduct}
                  setEditingProduct={setEditingProduct}
                  isRefund={isRefund}
                  setSelectedProductQuantity={setSelectedProductQuantity}
                />
              </div>

              <div className="my-3">
                <AddSalesProductTable
                  products={formik.values.products}
                  removeProduct={removeProduct}
                  editProduct={editProduct}
                />
              </div>

              {formik.values.products.length > 0 ? (
                <div className="my-3">
                  <SalesCalculation formik={formik} />
                </div>
              ) : null}
            </div>
            <div className="mt-16">
              <div className="flex justify-end gap-4">
                {isPosted && user.role_id != 1 ? (
                  <>
                    {" "}
                    <Button
                      label={"Go Back"}
                      icon="pi pi-arrow-left"
                      className="p-secondary-btn"
                      onClick={() => navigate(-1)}
                      type="button"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-red-btn"
                      type="button"
                      // disabled={showProductForm}
                      onClick={() => {
                        dispatch({ type: RESET_SPECIFIC_PRODUCT });
                        formik.resetForm();
                        setIsRefund(false);
                      }}
                    />
                    <Button
                      label={"Submit"}
                      icon="pi pi-check"
                      severity="help"
                      className="p-secondary-btn"
                      // disabled={showProductForm}
                      type="submit"
                    />
                    <Button
                      label={"Post"}
                      icon="pi pi-send"
                      className="p-primary-btn"
                      onClick={() => (formik.values.posted = 1)}
                      // disabled={showProductForm}
                      type="submit"
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </>
      )}
      {activeIndex === 1 && <AddCustomerSize formik={formik} />}
    </div>
  );
}
