import { useEffect, useRef, useState } from "react";
// import Navbar from "./navbar";
import Sidebar from "./sidebar";
import { ScrollPanel } from "primereact/scrollpanel";
import { TbSettings } from "react-icons/tb";
import { toast } from "react-toastify";
import { RESET_ERROR, RESET_SUCCESS } from "../store/Types/AuthTypes";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { AiOutlineAppstore } from "react-icons/ai";
import Navbar from "./navbar";
import {
  MdOutlineCategory,
  MdOutlineManageAccounts,
  MdShoppingCartCheckout,
} from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function RootLayout({ children }) {
  const dispatch = useDispatch();
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const { user, success, error } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch({ type: RESET_SUCCESS });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: RESET_ERROR });
    }
  }, [error]);
  const { t } = useTranslation();
  const items =
    user.role_id === 1
      ? [
          {
            name: "Dashboard",
            path: "/dashboard",
            icon: AiOutlineAppstore,
            showInSidebar: true,
          },
          {
            name: "Manage Categories",
            path: "/categories",
            icon: MdOutlineCategory,
            showInSidebar: true,
          },
          {
            name: "Add Category",
            path: "/add-categories",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Categories"],
          },
          {
            name: "Edit Category",
            path: "/edit-categories",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: ["Manage Categories"],
          },
          {
            name: "Manage Sub Categories",
            path: "/sub-categories",
            icon: BiCategoryAlt,
            showInSidebar: true,
          },
          {
            name: "Add Sub Category",
            path: "/add-sub-categories",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sub Categories"],
          },
          {
            name: "Edit Sub Category",
            path: "/edit-sub-categories",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: ["Manage Sub Categories"],
          },
          {
            name: "Manage Products",
            path: "/products",
            icon: MdOutlineLocalGroceryStore,
            showInSidebar: true,
          },
          {
            name: "Add Product",
            path: "/add-product",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Products"],
          },
          {
            name: "Edit Product",
            path: "/edit-product",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Products"],
          },
          {
            name: "Manage Purchase",
            path: "/purchase",
            icon: MdShoppingCartCheckout,
            showInSidebar: true,
          },
          {
            name: "Add Purchase",
            path: "/add-purchase",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Purchase"],
          },
          {
            name: "Edit Purchase",
            path: "/edit-purchase",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Purchase"],
          },
          // {
          //   name: "Manage Users",
          //   path: "/manage-users",
          //   icon: MdOutlineManageAccounts,
          //   showInSidebar: true,
          // },
          // {
          //   name: "Add User",
          //   path: "/add-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: ["Manage Users"],
          // },
          // {
          //   name: "Edit User",
          //   path: "/edit-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: ["Manage Users"],
          // },
          {
            name: "Manage Sales",
            path: "/sales",
            icon: MdOutlineManageAccounts,
            showInSidebar: true,
          },
          {
            name: "Add Sales",
            path: "/add-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sales"],
          },
          {
            name: "Edit Sales",
            path: "/edit-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sales"],
          },

          {
            name: t("manage_vendors"),
            path: "/manage-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: true,
          },
          {
            name: t("manage_customer_size"),
            path: "/manage-customer-size",
            icon: BsFillPersonLinesFill,
            showInSidebar: true,
          },
          {
            name: t("add_vendor"),
            path: "/add-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },
          {
            name: t("edit_vendor"),
            path: "/edit-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },
          {
            name: t("edit_vendor"),
            path: "/edit-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },
          {
            name: "Manage Order",
            path: "/manage-orders",
            icon: MdOutlineManageAccounts,
            showInSidebar: true,
          },

          {
            name: t("setup_add_work"),
            path: "/setup-add-work",
            icon: TbSettings,
            showInSidebar: false,
          },
          {
            name: t("setup"),
            path: null,
            icon: TbSettings,
            showInSidebar: true,
            menus: [
              {
                name: t("setup_add_work"),
                path: "/setup-add-work",
                icon: TbSettings,
                showInSidebar: true,
              },
            ],
          },
        ]
      : user.role_id === 2
      ? [
          {
            name: "Dashboard",
            path: "/dashboard-sales",
            icon: AiOutlineAppstore,
            showInSidebar: true,
          },
          {
            name: "Manage Categories",
            path: "/categories-sales",
            icon: MdOutlineCategory,
            showInSidebar: true,
          },
          {
            name: "Add Category",
            path: "/add-categories-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Categories"],
          },
          {
            name: "Edit Category",
            path: "/edit-categories-sales",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: ["Manage Categories"],
          },
          {
            name: "Manage Sub Categories",
            path: "/sub-categories-sales",
            icon: BiCategoryAlt,
            showInSidebar: true,
          },
          {
            name: "Add Sub Category",
            path: "/add-sub-categories-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sub Categories"],
          },
          {
            name: "Edit Sub Category",
            path: "/edit-sub-categories-sales",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: ["Manage Sub Categories"],
          },
          {
            name: "Manage Products",
            path: "/products-sales",
            icon: MdOutlineLocalGroceryStore,
            showInSidebar: true,
          },
          {
            name: "Add Product",
            path: "/add-product-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Products"],
          },
          {
            name: "Edit Product",
            path: "/edit-product-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Products"],
          },
          // {
          //   name: "Manage Users",
          //   path: "/manage-users",
          //   icon: MdOutlineManageAccounts,
          //   showInSidebar: true,
          // },
          // {
          //   name: "Add User",
          //   path: "/add-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: ["Manage Users"],
          // },
          // {
          //   name: "Edit User",
          //   path: "/edit-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: ["Manage Users"],
          // },
          {
            name: "Manage Sales",
            path: "/sales-sales",
            icon: MdOutlineManageAccounts,
            showInSidebar: true,
          },
          {
            name: "Add Sales",
            path: "/add-sales-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sales"],
          },
          {
            name: "Edit Sales",
            path: "/edit-sales-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: ["Manage Sales"],
          },
        ]
      : [];

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };
  return (
    <div className="flex">
      <Sidebar
        open={open}
        Nav_animation={Nav_animation}
        sidebarRef={sidebarRef}
        setOpen={(value) => setOpen(value)}
        pathname={pathname}
        isTabletMid={isTabletMid}
        items={items}
      />
      <div className="w-full h-screen overflow-auto">
        <Navbar setOpen={(value) => setOpen(value)} items={items} />
        {/* <ScrollPanel
          className=" bg-secondary h-96 "
          pt={{
            barY: {
              className: "bg-secondary",
            },
          }}
        > */}
        <div className="pt-6 mx-auto p-2 md:pr-2">{children}</div>
        {/* </ScrollPanel> */}
      </div>
    </div>
  );
}

export default RootLayout;
