import { color } from "framer-motion";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function AddVarientForm({
  formik,
  setShowVarientForm,
  setValidateVarient,
  varientUnit,
  showVarientForm,
  editingVarient,
  setEditingVarient,
}) {
  const { units } = useSelector((state) => state.ProductReducer);

  const { varientOptions, optionValues } = useSelector(
    (state) => state.ProductReducer
  );

  const optionValueFilter = (id) => {
    return optionValues.filter((option) => option.option_id === id);
  };

  const getVarientOptions = varientOptions.reduce((acc, option) => {
    acc[option.label] = "";
    return acc;
  }, {});

  const getEditVarientOptions = editingVarient
    ? varientOptions.reduce((acc, option) => {
        acc[option.label] = editingVarient[option.label] || "";
        return acc;
      }, {})
    : null;

  const getUnitName = (id) => {
    const unit = units.find((obj) => obj.id === id);
    return `${unit?.name}`;
  };

  const handleCancel = () => {
    // setShowVarientForm(false);
    setValidateVarient(false);
    setEditingVarient(null);
    formik.setValues({
      ...formik.values,
      varient: {
        ...getVarientOptions,
        quantity: "0",
        unit: 1,
        color: "", // Reset to empty instead of "N/A"
        design: "", // Reset to empty instead of "N/A"
        price: formik?.values?.samePrice ? formik?.values?.price : 0,
        purchasePrice: formik?.values?.samePurchasePrice
          ? formik?.values?.purchasePrice
          : 0,
      },
    });
  };

  const updateVarientInArray = (updatedVarient) => {
    const index = formik.values.varients.findIndex(
      (varient) => varient.id === editingVarient.id
    );

    if (index !== -1) {
      // Update the values of the object at the found index
      formik.values.varients[index] = {
        ...formik.values.varients[index],
        ...updatedVarient,
      };
    }

    formik.setValues({
      ...formik.values,
      varient: {
        ...getVarientOptions,
        quantity: "0",
        unit: 1,
        color: "N/A",
        design: "N/A",
        price: formik?.values?.samePrice ? formik?.values?.price : 0,
        purchasePrice: formik?.values?.samePurchasePrice
          ? formik?.values?.purchasePrice
          : 0,
      }, // Reset the varient form
    });

    setEditingVarient(null); // Reset the editingVarient state after updating
    // setShowVarientForm(false); // Close the varient form after updating

    toast.success("Varient Updated Successfully!");
  };

  const updateVarient = () => {
    if (editingVarient) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (formik.isValid) {
            updateVarientInArray(formik.values.varient);
          }
        } else {
          console.log("Validation errors:", errors);
        }
      });
    }
  };
  const handleAddVarient = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (formik.isValid) {
          const varientsArray = Array.isArray(formik.values.varients)
            ? formik.values.varients
            : [];

          const newVarient = {
            ...formik.values.varient,
            id: formik.values.varients.length + 1,
            color: formik.values.varient.color.trim() || "N/A",
            design: formik.values.varient.design.trim() || "N/A",
          };
          console.log(varientsArray);

          const similarVariantExists = varientsArray.some(
            (variant) =>
              variant.color === newVarient.color &&
              variant.design === newVarient.design &&
              variant.Size === newVarient.Size
          );

          if (similarVariantExists) {
            toast.error("A varient already exists.");
            return;
          }

          formik.setValues({
            ...formik.values,
            varients: [...varientsArray, newVarient],
            varient: {
              ...getVarientOptions,
              color: formik.values.varient.color,
              design: formik.values.varient.design,
              unit: formik.values.varient.unit,
              Size: formik.values.varient.Size,
              quantity: "0",
              price: formik?.values?.samePrice ? formik?.values?.price : 0,
              purchasePrice: formik?.values?.samePurchasePrice
                ? formik?.values?.purchasePrice
                : 0,
            },
          });

          // setShowVarientForm(false);
          setValidateVarient(false);
          toast.success("Varient Added Successfully!");
        }
      } else {
        console.log("Validation errors:", errors);
      }
    });
  };

  // const updateVarientInArray = (updatedVarient) => {
  //   const index = formik.values.varients.findIndex(
  //     (varient) => varient.id === editingVarient.id
  //   );

  //   if (index !== -1) {
  //     // Update the values of the object at the found index
  //     formik.values.varients[index] = {
  //       ...formik.values.varients[index],
  //       ...updatedVarient,
  //       color: updatedVarient.color.trim() || "N/A",
  //       design: updatedVarient.design.trim() || "N/A",
  //     };
  //   }

  //   formik.setValues({
  //     ...formik.values,
  //     varient: {
  //       ...getVarientOptions,
  //       quantity: "0",
  //       unit: 1,
  //       color: "",
  //       design: "",
  //       price: formik?.values?.samePrice ? formik?.values?.price : 0,
  //       purchasePrice: formik?.values?.samePurchasePrice
  //         ? formik?.values?.purchasePrice
  //         : 0,
  //     },
  //   });

  //   setEditingVarient(null);
  //   setShowVarientForm(false);

  //   toast.success("Varient Updated Successfully!");
  // };

  useEffect(() => {
    if (editingVarient) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        varient: {
          ...prevValues.varient,
          ...getEditVarientOptions,
          unit: editingVarient?.unit,
          quantity: editingVarient?.quantity,
          price: editingVarient?.price || formik.values.price,
          purchasePrice:
            editingVarient?.purchasePrice || formik.values.purchasePrice,
          color: editingVarient?.color || "", // Keep empty if not filled
          design: editingVarient?.design || "", // Keep empty if not filled
        },
      }));
    } else {
      // Initialize for a new variant
      formik.setFieldValue("varient.price", formik.values.price);
    }
  }, [editingVarient]);

  useEffect(() => {
    if (varientUnit) {
      varientOptions
        .filter((varient) => varient.unit_id == varientUnit)
        .forEach((varient) => {
          const filteredOptions = optionValueFilter(varient.id);
          if (
            filteredOptions.length === 1 &&
            !formik.values.varient[varient.label]
          ) {
            formik.setFieldValue(
              `varient.${varient.label}`,
              filteredOptions[0]?.value
            );
          }
        });
    }
  }, [varientUnit, varientOptions]);

  useEffect(() => {
    if (varientUnit) {
      const relevantOptions = varientOptions.filter(
        (varient) => varient.unit_id == varientUnit
      );

      const updatedVarient = { ...formik.values.varient };
      let hasChanges = false;

      relevantOptions.forEach((varient) => {
        const filteredOptions = optionValueFilter(varient.id);
        if (filteredOptions.length === 1) {
          if (updatedVarient[varient.label] !== filteredOptions[0]?.value) {
            updatedVarient[varient.label] = filteredOptions[0]?.value;
            hasChanges = true;
          }
        } else if (
          !filteredOptions.some(
            (option) => option.value === updatedVarient[varient.label]
          )
        ) {
          // If the current value is not in the new options, reset it
          updatedVarient[varient.label] = "";
          hasChanges = true;
        }
      });

      if (hasChanges) {
        formik.setValues({
          ...formik.values,
          varient: updatedVarient,
        });
      }
    }
  }, [varientUnit, varientOptions]);

  return (
    <>
      <div className="mt-3 flex justify-end">
        <Button
          label="Add Varient"
          icon="pi pi-plus"
          className="p-primary-btn"
          type="button"
          onClick={() => {
            setShowVarientForm(true);
            setValidateVarient(true);
          }}
        />
      </div>

      {showVarientForm && (
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {editingVarient ? "Edit" : "Add"} Varient
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor={`varient.unit`} className="">
                  Unit
                </label>

                <Dropdown
                  id={`varient.unit`}
                  name={`varient.unit`}
                  className="!w-full text-lg p-primary-input"
                  value={formik.values?.varient?.unit}
                  onChange={formik.handleChange}
                  options={units}
                  optionLabel="name"
                  optionValue="id"
                  pt={{
                    root: { className: "w-full" },
                    input: {
                      className: "w-full p-primary-input",
                    },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
            </div>

            {varientUnit &&
              varientOptions
                .filter((varient) => varient.unit_id == varientUnit)
                .map((varient) => {
                  const filteredOptions = optionValueFilter(varient.id);
                  const isSingleOption = filteredOptions.length === 1;

                  // Set the value for single options immediately
                  if (isSingleOption && !formik.values.varient[varient.label]) {
                    formik.setFieldValue(
                      `varient.${varient.label}`,
                      filteredOptions[0]?.value
                    );
                  }

                  return (
                    <div className="" key={varient.id}>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor={"varient." + varient.label}
                          className="capitalize"
                        >
                          {varient.label}
                        </label>

                        {isSingleOption ? (
                          <InputText
                            id={"varient." + varient.label}
                            name={"varient." + varient.label}
                            className="w-full text-lg p-primary-input"
                            value={
                              formik.values.varient[varient.label] ||
                              filteredOptions[0]?.value
                            }
                            disabled
                          />
                        ) : (
                          <Dropdown
                            id={"varient." + varient.label}
                            name={"varient." + varient.label}
                            className="!w-full text-lg p-primary-input"
                            value={formik.values.varient[varient.label]}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `varient.${varient.label}`,
                                e.value
                              );
                            }}
                            options={filteredOptions}
                            optionLabel="value"
                            optionValue="value"
                            filter
                            pt={{
                              root: { className: "w-full" },
                              input: { className: "w-full p-primary-input" },
                              filterIcon: { className: "ml-1" },
                              filterInput: { className: "pl-6" },
                            }}
                          />
                        )}
                      </div>
                      {formik.touched?.varient?.[varient.label] &&
                        formik.errors?.varient?.[varient.label] && (
                          <div className="p-error">
                            {formik.errors?.varient?.[varient.label]}
                          </div>
                        )}
                    </div>
                  );
                })}

            {/* <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.quantity" className="">
                  Quantity
                </label>
                <span className=" w-full">
                  <InputText
                    disabled
                    type="number"
                    id="varient.quantity"
                    name="varient.quantity"
                    className="w-full text-lg p-primary-input"
                    value={formik.values?.varient?.quantity}
                    onChange={formik.handleChange}
                  />
                  <small
                    id="varient.quantity-unit"
                    className=" flex justify-end text-blue-800"
                  >
                    per {getUnitName(formik.values.varient.unit)}
                  </small>
                </span>
              </div>
              {formik.touched?.varient?.quantity &&
                formik.errors?.varient?.quantity && (
                  <div className="p-error">
                    {formik.errors?.varient?.quantity}
                  </div>
                )}
            </div> */}

            {/* <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.purchasePrice" className="">
                  Purchase Price
                </label>
                <span className=" w-full">
                  <InputNumber
                    disabled
                    id="varient.purchasePrice"
                    name="varient.purchasePrice"
                    className="w-full text-lg p-primary-input"
                    value={
                      formik?.values?.samePurchasePrice
                        ? formik?.values?.purchasePrice
                        : formik?.values?.varient?.purchasePrice
                    }
                    onValueChange={formik.handleChange}
                    prefix="RS "
                    // disabled={formik.values.samePurchasePrice}
                  />
                  <small
                    id="varient.purchasePrice-unit"
                    className=" flex justify-end text-blue-800"
                  >
                    per {getUnitName(formik.values.varient.unit)}
                  </small>
                </span>
              </div>
              {formik.touched?.varient?.purchasePrice &&
                formik.errors?.varient?.purchasePrice && (
                  <div className="p-error">
                    {formik.errors?.varient?.purchasePrice}
                  </div>
                )}
            </div> */}

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.price" className="">
                  Sale Price
                </label>
                <span className=" w-full">
                  <InputNumber
                    id="varient.price"
                    name="varient.price"
                    className="w-full text-lg p-primary-input"
                    placeholder="Rs 0"
                    value={formik.values.varient.price}
                    onValueChange={(e) => {
                      formik.setFieldValue("varient.price", e.value);
                      if (formik.values.samePrice) {
                        formik.setFieldValue("price", e.value);
                      }
                    }}
                    prefix="RS "
                    disabled={formik.values.samePrice}
                  />
                  <small
                    id="varient.price-unit"
                    className=" flex justify-end text-blue-800"
                  >
                    per {getUnitName(formik.values.varient.unit)}
                  </small>
                </span>
              </div>
              {formik.touched?.varient?.price &&
                formik.errors?.varient?.price && (
                  <div className="p-error">{formik.errors?.varient?.price}</div>
                )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.color" className="">
                  Color
                </label>
                <span className=" w-full">
                  <InputText
                    id="varient.color"
                    name="varient.color"
                    className="w-full text-lg p-primary-input"
                    value={formik?.values?.varient.color}
                    onChange={(e) => {
                      formik.setFieldValue("varient.color", e.target.value);
                    }}
                    onBlur={(e) => {
                      // If the field is empty on blur, set it to "N/A"
                      if (!e.target.value.trim()) {
                        formik.setFieldValue("varient.color", "N/A");
                      }
                    }}
                    placeholder="N/A"
                  />
                </span>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.design" className="">
                  Design
                </label>
                <span className=" w-full">
                  <InputText
                    id="varient.design"
                    name="varient.design"
                    className="w-full text-lg p-primary-input"
                    value={formik?.values?.varient.design}
                    onChange={(e) => {
                      formik.setFieldValue("varient.design", e.target.value);
                    }}
                    onBlur={(e) => {
                      // If the field is empty on blur, set it to "N/A"
                      if (!e.target.value.trim()) {
                        formik.setFieldValue("varient.design", "N/A");
                      }
                    }}
                    placeholder="N/A"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-end gap-4">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={handleCancel}
              />
              <Button
                label={editingVarient ? "Edit" : "Add"}
                icon="pi pi-check"
                className="p-secondary-btn"
                type="button"
                onClick={editingVarient ? updateVarient : handleAddVarient}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
